.swiper-container.swiper-fix-height{
    width: 100%;
    height: 580px;
}
.swiper-wrapper{
    .swiper-slide{
        background:no-repeat 50%/cover;
    }
}
.swiper-vertical{
    height: 480px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

//Swiper-Scrollbar
.swiper-container-horizontal{
    >.swiper-scrollbar{
    background-color: rgba($dark,.25);
    border-radius: 0;
    height: 4px;
    width: 100%;
    bottom: 0;
    left: 0;
    >.swiper-scrollbar-drag{
        border-radius: 0;
        background-color: $dark;
    }
    }
}

//Swiper navigation
.swiper-button-next, .swiper-button-prev{
    color: $secondary;
    background-color: $light;
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
    margin-top: -1.25rem;
    &:after{
        font-size: 1.5rem;
    }
    &:focus{
        outline: 0;
    }
}
.swiper-pagination-bullet{
    background: rgba($primary,1);
    &:focus{
        outline: 0;
    }
}
.text-white,.text-light{
    
    .swiper-pagination-bullet{
        background: $white;
    }
}
//Swiper Caption layers
.sliderLayer-1,.sliderLayer-2,.sliderLayer-3{
    transition: opacity 0.4s ease, transform 0.4s ease;
transition-property: opacity,transform;
transform: translateZ(0) translate(0, 25px);
filter: alpha(opacity=0);
    -webkit-transform: translateZ(0) translate(0, 25px);
opacity:0;
transition-duration: .3s;
transition-timing-function: cubic-bezier(.25,.1,.25,1);
-webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    perspective: 1000;
}
  .swiper-slide-active{
    .sliderLayer-1,.sliderLayer-2,.sliderLayer-3{
        opacity: 1;
        -webkit-transform: translateZ(0) translate(0, 0);
    -moz-transform: translateZ(0) translate(0, 0);
    -ms-transform: translateZ(0) translate(0, 0);
    transform: translateZ(0) translate(0, 0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    perspective: 1000;
    }
      .sliderLayer-1{
          transition-delay: .4s;
      }
      .sliderLayer-2{
        transition-delay: .6s;
    }
    .sliderLayer-3{
        transition-delay: .8s;
    }
  }

  @include media-breakpoint-down(sm) {
    .swiper-container.swiper-fix-height{
        height: 380px;
    }
    .swiper-button-next, .swiper-button-prev{
        width: 1.5rem;
        height: 1.5rem;
        margin-top: -.75rem;
        &:after{
            font-size: 1rem;
        }
    }
  }
