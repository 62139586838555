//dropdown-customization
.dropdown-item {
    text-transform: $dropdown-text-transform;
    letter-spacing: $dropdown-letter-spacing;
}
//Fullscreen .dropdown-menu
.fullscreen-dropdown-menu {
    .fullscreen-dropdown-item {
        display: block;
        text-align: center;
        font-size: $font-size-base;
        padding: 0.15rem 0.75rem;
    }
}

//dark
.dropdown-menu-dark {
    .form-control {
        border-color: rgba($white, 0.175);
        background-color: $gray-700;
    }
}

//toggle-dropdown

//transition dropdown
@-webkit-keyframes dropdown-show {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease;
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes dropdown-show {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease;
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@include media-breakpoint-up(lg) {
    .dropdown-menu {
        display: block;
        visibility: hidden;
        opacity: 0;
        transform: translate3d(0, 6px, 0);
        transition: transform 350ms cubic-bezier(0.4, 0, 0.2, 1), opacity 0.35s;
        &.show {
            opacity: 1;
            transform: none;
            visibility: visible;
            transform-origin: bottom center;
        }
    }

    .dropdown-submenu {
        .dropdown-menu {
            opacity: 0;
            visibility: hidden;
            display: block;
        }
        &:hover > .dropdown-menu {
            opacity: 1;
            visibility: visible;
            animation: dropdown-show 0.4s both;
            -webkit-animation: dropdown-show 0.4s both;
        }
    }

    //search
    .dropdown-search {
        min-width: 460px;
        padding: 2rem;
    }
    .dropdown-submenu {
        position: relative;
        .dropdown-menu {
            top: 0;
            margin-top: -4px;
            left: 100%;
            right: auto;
        }
        &:hover {
            > .dropdown-menu {
                display: block;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .dropdown-submenu {
        .dropdown-menu {
            display: block;
            box-shadow: none;
            padding: 0.2rem 0;
            padding-left: 1rem;
        }
    }
}
