//borders
//border-width-2-9 / border width-1 is default apply with .border-class
@for $i from 2 through 9 {
  $border-width: ($i);
  .border-width-#{$border-width} {
    border-width: #{$border-width}px !important;
  }
}

//border style dashed
@for $i from 1 through 5 {
  $border-dash: ($i);
  .border-dashed-#{$border-dash} {
    border-width: #{$border-dash}px !important;
    border-style: dashed !important;
    border-color: $border-color;
  }
}

.divider-shape{
  display: inline-block;
  position: relative;
  &:before {
    content: "";
    width:50%;
    height: 2px;
    background-color: $primary;
    display: inline-flex;
    position: absolute;
    left:0;
    bottom: 0;
  }
}


//separated borders
// separated items
.separated {
	overflow: hidden;

	> * {
		box-shadow: -2px -2px 0px 0px $border-color;
    -moz-box-shadow: -2px -2px 0px 0px $border-color;
		&:not(:last-child) {
			margin-bottom: 0!important;
		}
	}
}



//Popover header border bottom
.popover-header{
  border-bottom-color: $popover-header-border-color;
}