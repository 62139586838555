//Preloader

body{
    .preloader {
        &:before,
        &:after {
            content: "";
            position: fixed;
            z-index: 99999;
        }
        &:before {
            transition: transform .2s ease-in-out;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $dark;
        }
        &:after {
            top: 50%;
            left: 50%;
            width: 4rem;
            height: 4rem;
            margin: -2rem 0 0 -2rem;
            border-radius: 50%;
            opacity: 0.4;
            background: $primary;
            animation: preloaderAnim 0.7s linear infinite alternate forwards;
        }
    }
    &.loaded{
        .preloader{
          display: none;
        }
    }
}

//Preloeader anim
@keyframes preloaderAnim {
    to {
        opacity: 1;
        transform: scale3d(0.5, 0.5, 1);
    }
}
@-webkit-keyframes preloaderAnim {
    to {
        opacity: 1;
        transform: scale3d(0.5, 0.5, 1);
    }
}
