
//Form styles
.form-floating-group{
position: relative;
margin-bottom: $spacer;
label {
    height: 3.125rem;
    padding: .75rem;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    color: inherit;
    pointer-events: none;
    cursor: text;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
}
input{
    height: 3.125rem;
    padding: .75rem;
    &::-ms-input-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::placeholder {
        color: transparent;
      }
      &:not(:-moz-placeholder-shown) {
        padding-top: 1.25rem;
        padding-bottom: .25rem;
      }
      &:not(:placeholder-shown) {
        padding-top: 1.25rem;
        padding-bottom: .25rem;
      }
}
input:not(:-moz-placeholder-shown) ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: $text-muted;
  }
  input:not(:placeholder-shown) ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: $text-muted;
  }
}



//Choices Form
.choices{
  >.dropdown-menu {
    width: 100%;
  }
  &:after{display:none}
  .choices__list--single{
    padding:0;
  }
}
.choices__placeholder{
  color: $input-color;
}
.choices__list.dropdown-menu.show{
  >.form-control{
    display: none;
  }
}

//placeholder on dark bg
.text-white,.text-light{
  &.form-control,&select,&input{
    &::placeholder{
      color: rgba($white,.75);
    }
    &::-webkit-input-placeholder{
      color: rgba($white,.75);
    }
  }
}