//flatpicker custom style
.flatpickr-calendar{
    border-radius: 0px;
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
    .flatpickr-current-month{
        padding-top: .35rem;
        font-size: 130%;
        font-weight: $font-weight-bolder;
        .numInputWrapper{
            width: 7ch;
            input:disabled{
                &+ .arrowUp,&+ .arrowUp + .arrowDown{
                    pointer-events: none;
                }
                &:hover{
                    &+ .arrowUp,&+ .arrowUp + .arrowDown{
                        opacity:0;
                    }
                }
            }
        }
    }
    span.span.flatpickr-weekday{
        color: $dark;
        font-weight: $font-weight-normal;
    }
    .flatpickr-day{
        border-radius: 0;
        transition: all .25s ease-in-out;
        &:not(.flatpickr-disabled){
        color: $body-color;
        }
        &.selected{
            background: $primary;
            border-color:$primary;
            color: $white;
        }
        &.today{
            border-color: $secondary;
            color: $secondary;
            &:hover,&:focus{
                color: $white;
                background: $secondary;
            }
        }
        &:not(.flatpickr-disabled):not(.selected):hover,&:not(.flatpickr-disabled):not(.selected):focus{
            background: $light;
            border-color:$border-color;
        }
    }
}