//height and width styles

  //min height of viewport size - 
  @for $i from 1 through 10 {
    $heightVh: ($i * 10);
    .min-height-#{$heightVh} {
      min-height: #{$heightVh}vh;
    }
  }
  //height of viewport size - 
  @for $i from 1 through 10 {
    $heightVh: ($i * 10);
    .vh-#{$heightVh} {
      height: #{$heightVh}vh;
    }
  }
    //width of viewport size - 
    @for $i from 1 through 10 {
        $widthVw: ($i * 10);
        .vw-#{$widthVw} {
          width: #{$widthVw}vw;
        }
      }


        //width-height for icons
  @for $i from 1 through 30 {
    $width: ($i * 10);
    .width-#{$width} {
      width: #{$width}px !important;
    }
  }
  @for $i from 1 through 30 {
    $height: ($i * 10);
    .height-#{$height} {
      height: #{$height}px !important;
    }
  }



  //layout styles
  body,html{
    height: 100%;
  }
  .layout-pancake.d-grid{
    min-height: 100%;
  /* grid container settings */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
  }
