//Background image style
.bg-cover {
    background: no-repeat 50%/cover;
}

.bg-no-repeat{
    background-repeat: no-repeat;
}
.bg-center{
    background-position: center center;
}
.bg-top-center{
    background-position: top center;
}
.bg-top-left{
    background-position: top left;
}
.bg-top-right{
    background-position: top right;
}



//background-blur // Overlay
.bg-overlay:not(.position-relative){
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   opacity:.65;
   z-index: 0;
   &+*{
       z-index: 1;
   }
}
.bg-blur{
    -webkit-backdrop-filter: blur(72px);
            backdrop-filter: blur(72px);
}



//.Overlay-content-box
.overlay-linear{
background-image: linear-gradient(to bottom,transparent 52%, rgba($dark,.75) 100%);
}



//Opacity
@for $i from 1 through 9 {
    $opacity: ($i * 10);
    .opacity-#{$opacity} {
        opacity:  $i / 10;
    }
  }



  //Animated background gif
  .bg-dotted-gif{
    &:before{
        background-image: url('../img/bg-animated.gif');
        content: "";
        position: absolute;
        left:0;
        top:0;
        width: 100%;
        height: 100%;
        opacity:.06;
    }
 }

 @include media-breakpoint-up(xl){
.side-cover {
    width: calc(100% + (100vw - 1040px)/2 + 2.5rem);
    &.position-absolute:not(.position-relative){
        position: absolute;
    }
    &.left-0{
        left:0;
    }
    &.top-0{
        top:0;
    }
    &.bottom-0{
        bottom:0;
    }
    &.right-0{
        right:0;
    }
}
}


.bg-dust{
    position: fixed;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
  background-color: $dark;
  background-image:url('../img/dust.png');
  z-index: 0;
  background-repeat: repeat;
}



//Background-blend-modes
.bg-multiply{
    background-blend-mode: multiply;
}
.bg-overlay{
    background-blend-mode: overlay;
}
.bg-screen{
    background-blend-mode: screen;
}
.bg-difference{
    background-blend-mode: difference;
}
.bg-lighten{
    background-blend-mode: lighten;
}
.bg-darken{
    background-blend-mode: darken;
}