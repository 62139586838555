
// stylelint-disable value-keyword-case
$font-family-sans-serif:'Merriweather', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-display:'Playball', cursive !default;
$font-family-header: 'Lato' !default;
$font-family-monospace:SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-weight-boldest:900 !default;


$line-height-base: 1.85 !default;
$letter-spacing-headings: .05rem !default; // for Display-[1-6], h1,h2,h3
$helper-tan: #F1A663 !important;
/*
// stylelint-enable value-keyword-case
$font-family-base: $font-family-sans-serif !default;
$font-family-code: var(--bs-font-monospace) !default;
// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null !default;
$font-size-base:.9375rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.845 !default;
$font-size-lg: $font-size-base * 1.175 !default;
$font-weight-lighter:200 !default;
$font-weight-light:300 !default;
$font-weight-normal:400 !default;
$font-weight-semibold:600 !default;
$font-weight-bold:700 !default;
$font-weight-bolder:800 !default;
$line-height-base: 1.6 !default;
$line-height-sm: 1.2 !default;
$line-height-lg: 1.8 !default;
$h1-font-size: $font-size-base * 2.25 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.225 !default;
$h6-font-size: $font-size-base   * 1.0225  !default;
$headings-margin-bottom: $spacer / 2 !default;
$headings-line-height:$line-height-sm !default;
$headings-color: $black !default;
$sub-sup-font-size: 0.75em !default;
$text-muted: $gray-500 !default;
$hr-margin-y:                 $spacer !default;
$hr-color:                    $border-color !default;
$hr-height:                   $border-width !default;
$hr-opacity:                  1 !default;
// scss-docs-start display-headings
$display-font-sizes: (
    1: 6rem,
    2: 5rem,
    3: 4.25rem,
    4: 3.5rem,
    5: 3rem,
    6: 2.75rem
) !default;
$display-line-height: 1.1 !default;
*/
