//CSS Grid template layouts - Best work in sticky sidebar footer and more..

.layout {
    display: grid;
    min-height: 100vh;
    grid-gap: 0;
    grid-template-areas:
      "aside"
      "main";
  }
  .aside {
    grid-area: "aside";
    background-color:$white;
    padding: 1.5rem;
    border-right: 1px solid $border-color;
  }
  .main {
    grid-area: "main";
    padding:0 1rem;
  }

  @include media-breakpoint-up(md){
    .layout {
        grid-template-columns: auto 1fr;
        grid-template-areas: "aside content";
        aside {
            width: 220px;
          }
          .main{
              padding: 2rem;
          }
      }
      

  }