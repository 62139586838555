// Modal style

.modal-fixed-right {
    padding-right: 0 !important;
    .modal-dialog-vertical {
        margin: 0;
        margin-left: auto;
        max-width: 320px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        height:100%;
        .modal-content {
            overflow: auto;
            height:100%;
        }
    }
    &.fade .modal-dialog-vertical {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    &.show {
        .modal-dialog-vertical {
            transform: translateX(0px);
            transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
        }
    }
    .modal-header {
        padding: 1.5rem 1rem;
        border-radius: 0 !important;
        position: relative;
        border-bottom: 0;
        .close {
            position: absolute;
            top: 0;
            right: 0;
            color: inherit;
            opacity: 0.75;
            margin-top: 0.5rem;
            margin-right: 0.25rem;
            outline: 0;
            background-color: transparent;
            border: 0;
            &:not(:disabled):hover,
            &:hover:not(.disabled) {
                opacity: 1;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .modal-fixed-right {
        .modal-dialog-vertical {
            max-width: 380px;
            .modal-header {
                padding: 2rem 2.5rem;
            }
            .modal-body {
                > ul {
                    padding: 1.25rem;
                }
            }
        }
    }
}
