body{
    letter-spacing: $letter-spacing-base;
    text-rendering: optimizeLegibility;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6{
    font-weight: $display-font-weight;
    font-family: $display-font-family;
    letter-spacing: $letter-spacing-headings;
}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
    font-family:$headings-font-family;
    letter-spacing: $letter-spacing-headings-sm;
    text-transform: inherit;
}
// h1,h2,h3,h4,h5,h6{
//     text-transform: uppercase;
// }
h3,h4,h5,h6,.h3,.h4,.h5,.h6{
    font-weight: $headings-font-weight-sm;
}
b, strong {
    font-weight: $font-weight-semibold;
    font-family: $font-family-base;
}

//Link
a{
    transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;
    &:focus,&:active{
        outline: 0!important;
    }
}
a.text-muted,a.text-secondary,a.text-dark,a.text-body{
    &:hover{
        color: $component-active-bg!important;
    }
}

//Texts style
.text-mono{
    font-family: $font-family-monospace;
    text-transform: unset;
    letter-spacing: 0;
}
.text-display{
    font-family: $font-family-display;
    letter-spacing: $letter-spacing-base;
    text-transform: unset;
    font-weight: 400;
}
.text-sm,small{
    font-size:$small-font-size;
}
.text-italic{
    font-style: italic;
}
.text-unset{
    text-transform: unset;
}
.text-highlight{
display: inline-block;
position: relative;
z-index: 0;
&:before{
    content: "";
    position: absolute;
    left:0;
    bottom: 0;
    background-color:rgba($primary,.35);
    height: 40%;
    width:100%;
    margin-bottom: .2rem;
    z-index: -1;
}
}


//text-rotation
.text-verticle{
    display: inline-block;
    transform: rotateY(-90deg);
}

.line-height-1{
    line-height: 1;
}
.line-height-sm{
    line-height: $line-height-sm;
}
.line-height-base{
    line-height: $line-height-base;
}
//letter-spacing
.text-space-0{
    letter-spacing: 0;
}
.text-space-1{
    letter-spacing: .125rem;
}
.text-space-2{
    letter-spacing: .25rem;
}
.text-space-3{
    letter-spacing: .375rem;
}
.text-space-4{
    letter-spacing: .5rem;
}
.text-space-5{
    letter-spacing: .625rem;
}
.fw-semibold{
    font-weight: $font-weight-semibold!important;
}
    //TextOutline
    @supports (-webkit-text-stroke: 1px) {
        @each $color, $value in $theme-colors {
            .text-outline-#{$color} {
                -webkit-text-stroke:1px $value;
                -webkit-text-fill-color: transparent;
            }
          }
      }




    ///Link colors on dark bg
.text-white,.dropdown-menu-dark{
    a:not(.btn):not(.badge):not(.dropdown-item){
        color: rgba($white,.75);
        @include transition($transition-base);
        &:hover{
            color: $white;
        }
    }
    .text-muted{
        color: rgba($white,.65)!important;
    }
    .border-white,.border-light{
        border-color: rgba($white,.175)!important;
    }
    .border-right{
        border-right-color: rgba($white,.225)!important;
    }
    h1,h2,h3,h4,h5,h6,.display-1,.display-2,.display-3,.display-4,.display-5,.display-6{
        color: $white;
    }
}



    //Code
    .highlight pre code {
        color: $secondary;
        .c {
            color: $text-muted;
        }
        .nt {
            color: $info;
        }
        .s {
            color:$danger;
        }
        &.terminal{
            position: relative;
            display: inline-block;
            margin: .2rem;
            padding: 0 .5rem 0 2rem;
        }
    }
    pre{
        font-size: $font-size-base;
    }
    .chroma {
        .c1 {
            color: #727272;
        }
        .o {
            color: #555;
        }
        .mh {
            color: #c24f19;
        }
        .k {
            color: #069;
        }
        .s2 {
            color: #c30;
        }
    }
