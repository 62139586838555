//Navs scss

.nav{
    &.nav-underline{
        border-bottom: 1.5px solid $border-color;
        .nav-link{
            color: currentColor;
            border-bottom: 1.5px solid  $border-color;
            position: relative;
            margin-bottom: -1.5px;
            &.active{
                border-bottom-color: $primary;
                color: $primary;
            }
        }
    }
    &.nav-pills{
        .nav-link{
            color: $body-color;
            &.active{
                color: rgba($white,1);
            }
        }
    }
}
.text-white,.text-light{
    .nav.nav-pills{
        .nav-link{
            color: rgba($white,.75);
            &.active,&:hover{
                color: rgba($white,1);
            }
        }
    }
}


//Nav Aside
.nav-aside{
    li{
        display: flex;
        flex-direction: column;
        .nav-link{
            color: $body-color;
            text-transform: uppercase;
            letter-spacing: .1rem;
            font-weight: $font-weight-bold;
        }
    }
}
.nav{
    &.flex-column{
        .nav-link{
            padding-left: 0;
            padding-right: 0;
        }
    }
}