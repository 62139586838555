//Cards

.card-item {
    contain: content;
    padding: .5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    .card-item-header {
        position: relative;
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($dark, 0.05);
            content: "";
            background-blend-mode: overlay;
        }
        > img {
            transition: opacity 0.25s ease-in-out;
            position: relative;
            width: 100%;
        }
    }
    .card-item-body{
        padding: 1rem .25rem;
    }
    &:hover {
        .card-item-header > img {
            opacity: 0.9;
        }
    }
    &:hover{
        box-shadow: $box-shadow-lg;
    }
}
.card-item-add-btn > a {
    transition: height 0.3s ease-in-out, opacity 0.25s linear;
    opacity: 0;
    height: 0;
}
.card-item:hover {
    .card-item-add-btn > a {
        opacity: 1;
        height: auto;
    }
}
.card-item-add {
    position: relative;
    overflow: hidden;
    min-width: 35%;
    text-align: right;
    .btn-add {
        padding: .25rem .5rem;
        opacity: 0;
        visibility: hidden;
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
        transition:all .25s ease;
    }
}
.item-price {
    position: absolute;
    right: 0;
    top: 0;
    transition: transform .25s ease-in-out, opacity .2s linear;
}
.card-item {
    &:hover {
        .card-item-add {
            .btn-add {
                transform: translateY(0%);
                -webkit-transform: translateY(0%);
                opacity: 1;
                visibility: visible;
            }
            .item-price{
                transform: translateY(-100%);
                -webkit-transform: translateY(-100%);
            }
        }
    }
}




//Remove it
.element-icon-box{
    display: flex;
    flex-wrap: wrap;
    >img{
      padding: .5rem;
      background-color: $light;
      margin: .35rem;
      display: block;
      width:64px;
      height: auto;
    }
    svg{
        margin: .25rem;
        padding: .5rem;
        background-color: $light;
    }
}