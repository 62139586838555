// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-background: darken($background, 8%),
  $hover-border: darken($border, 12%),
  $hover-color: color-contrast($hover-background),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12%),
  $active-color: color-contrast($active-background)
) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;

  &:hover {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    @include box-shadow(0 .35rem 1.25rem  rgba(mix($color, $border, 20%), .175));
  }

  .btn-check:focus + &,
  &:focus {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    @if $enable-shadows {
      @include box-shadow(0 .35rem 1.25rem  rgba(mix($color, $border, 20%), .175));
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: null;
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow(0 .125rem .5rem  rgba(mix($color, $border, 12%), .175));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow:null;
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: $background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $border;
  }
}

@mixin button-outline-variant(
  $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  color: $color;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
    @if $enable-shadows {
      box-shadow: 0 .75rem 1rem rgba($color, .225);
     } @else {
       // Avoid using mixin so we can pass custom focus shadow properly
       box-shadow: null;
     }
  }

  .btn-check:focus + &,
  &:focus {
    box-shadow: 0 .25rem .5rem rgba($color, .125);
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
       box-shadow: 0 .25rem .5rem rgba($color, .125);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: null;
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
  padding: $padding-y $padding-x;
  @include font-size($font-size);
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);
}