.backTop{
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    top: 0;
    margin-top: -1rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    border-top-left-radius: $border-radius-sm;
    z-index: 1;
    >*{
        transform: rotate(-45deg);
    }
}