//Flex center-align content/icon
.flex-align-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}

//Zindex
.z-index-1 {
    z-index: 1 !important;
}
.z-index-2 {
    z-index: 2 !important;
}
.z-index-3 {
    z-index: 3 !important;
}
//Flip element
.flip-y {
    transform: matrix(1, 0, 0, -1, 0, 0);
    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
}
.flip-x {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
}

@include media-breakpoint-up(md) {
    .position-absolute-md {
        position: absolute;
    }
    .end-md-0 {
        right: 0;
    }
    .start-md-0 {
        left: 0;
    }
    .top-md-0 {
        top: 0;
    }
    .bottom-md-0 {
        bottom: 0;
    }
    .w-md-50 {
        width: 50% !important;
    }
    .w-md-25 {
        width: 25% !important;
    }
    .w-md-75 {
        width: 75% !important;
    }
    .w-md-100 {
        width: 100% !important;
    }
    .h-md-25 {
        height: 25% !important;
    }
    .h-md-50 {
        height: 50% !important;
    }
    .h-md-75 {
        height: 75% !important;
    }
    .h-md-100 {
        height: 100% !important;
    }
}

@include media-breakpoint-up(lg) {
    .position-absolute-lg {
        position: absolute;
    }
    .end-lg-0 {
        right: 0;
    }
    .start-lg-0 {
        left: 0;
    }
    &.top-lg-0 {
        top: 0;
    }
    .bottom-lg-0 {
        bottom: 0;
    }
    .w-lg-50 {
        width: 50% !important;
    }
    .w-lg-25 {
        width: 25% !important;
    }
    .w-lg-75 {
        width: 75% !important;
    }
    .w-lg-100 {
        width: 100% !important;
    }
    .h-lg-25 {
        height: 25% !important;
    }
    .h-lg-50 {
        height: 50% !important;
    }
    .h-lg-75 {
        height: 75% !important;
    }
    .h-lg-100 {
        height: 100% !important;
    }
}
