//Label & Badges
.item-badge {
    position: absolute;
    left: -6px;
    top: 0;
    background-color: currentColor;
    color: $white;
    font-size: $font-size-sm * .9375;
    font-weight: 500;
    padding: 5px 6px 4px;
    max-width: 50%;
    text-transform: uppercase;
    border-color: currentColor transparent;
    z-index: 1;
    &:before{
        position: absolute;
        bottom: -6px;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-color: inherit;
        left: 0;
        border-width: 6px 0 0 6px;

    }
    .badge-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .item-badge{
        &.badge-primary{
            background-color: $primary;
            border-color: $primary transparent;
        }
        &.badge-dark{
            background-color: $dark;
            border-color: $dark transparent;
        }
        &.badge-dark{
            background-color: $dark;
            border-color: $dark transparent;
        }
    }
}
@each $color, $value in $theme-colors {
    .item-badge.badge-#{$color} {
        background-color: $value;
        border-color: $value transparent;
    }
  }